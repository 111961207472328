import React from "react";
import { useState } from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import { useScrollWithShadow } from "../../hooks/useScrollWithShadow";
import Card from "../common/card";

import "./styles/works.css";

const jobs = [
	{
		company: "Doran Jones Inc",
		logo: "./dj_logo.png",
		date: "Aug 2023 - Present",
		title: "Software Engineer",
		description: [
			`Built a reusable Storybook component library from ground up for TransUnion, ranging from fundamental elements to more complex
			components, employing ReactJS and SCSS with an emphasis on accessability standards.`,
			`Developed a robust data table component with support for both server-side and client-side rendering, incorporating advanced features
			such as seamless pagination, efficient table filtering, sortable columns, row selection, and the capability to customize columns and cells
			based on provided data.`,
			`Established and implemented automated CI checks within GitHub, orchestrating unit tests, linters, and formatting validations.
			Successfully enforced conventional commits on pull requests, contributing to a substantial improvement in code quality.`,
			`Authored detailed documentation for the component library, providing clear usage guidelines, comprehensive component anatomy,
			thorough props documentation, and detailed descriptions to ensure accessibility and ease of implementation.`,
			`Offer mentorship to junior developers, covering topics such as conducting comprehensive pull request reviews and effective debugging
			techniques.`
		],
	},
	{
		company: "1stDibs",
		logo: "./dibs-logo.png",
		date: "Dec 2021 - Jul 2023",
		title: "Front-end Software Engineer",
		description: [
			"Developed single page applications on the SEO and Growth team to improve user experience and organic traffic growth, utilizing ReactJS, TypeScript, GraphQL, Redux, and SASS.",
			"Led the dynamic creation of product listing pages based on user searches to enhance SEO performance on both US and international sites, resulting in a 450,000 increase in monthly user sessions.",
			"Implemented AI generated content for dynamically created product listing pages, leveraging key factors such as the most relevant creator, price range, and product attributes resulting in a 75% increase in pages indexed by Google.",
			"Developed and A/B tested features such as pulling in relevant Q&A to dynamic product listing pages from other page types and internal linking to pages via description text match, resulting in an 80% increase traffic collectively.",
			"Collaborated with backend engineers and managers to identify and resolve SEO issues such as wasted Google site crawl budget and page load speed performance to increase Google page indexability.",
			"Wrote unit tests using Jest to ensure reliability of front-end application functionality.",
		],
	},
	{
		company: "RightPoint",
		logo: "./rightpoint_logo.jpeg",
		date: "Jan 2020 - Dec 2021",
		title: "Front-end Software Engineer",
		description: [
			"Delivered responsive user interfaces for e-commerce businesses, considering factors such as product catalogs, checkout processes, and user flows, to optimize the online shopping experience and drive customer engagement and conversions.",
			"Collaborated with engineers, project managers, and designers to build the user interface of a B2B e-commerce platform for Anheuser Busch, the world's largest brewing company, resulting in a 130% increase in B2B sales.",
			"Led the development of a single page application for Industry West furniture store using ReactJS and GraphQL and Magento backend, resulting in a 400% return on investment within the first week of launch.",
			"Created maintainable and reusable React components for e-commerce builds used by multiple clients to ensure atomic design pattern and decrease engineering overhead.",
			"Wrote automation tests using Cypress, Selenium, and Jest to measure performance and ensure code consistency/correctness across project builds.",
		],
	},
	{
		company: "OTC Markets",
		logo: "./otc-markets-logo.jpeg",
		date: "June 2019 - Dec 2019",
		title: "Front-end Software Engineer Intern",
		description: [
			"Developed single-page applications and reusable components using ReactJS and SASS with a mobile-first approach.",
			"Translated InvisionApp design mockups into responsive and interactive features while ensuring compatibility across various browsers/devices.",
			"Developed a podcast web application and a globally accessible audio player on the OTC Podcast web app using ReactJS.",
			"Coordinated with UX designers and back-end developers to build features and solve performance issues.",
		],
	},
];
const Works = () => {
	const [currentJob, setCurrentJob] = useState(jobs[0]);
	const { boxShadow, onScrollHandler } = useScrollWithShadow();
	
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						{jobs.map((job) => {
							const { company, logo, date, title } = job || {};

							return (
								<div
									className={`work ${
										currentJob === job ? "active" : ""
									}`}
									onClick={() => setCurrentJob(job)}
								>
									<div className="work-image-wrapper">
										<img
											src={logo}
											alt={company}
											className="work-image"
										/>
									</div>
									<div className="work-title-wrapper">
										<div className="work-title">
											{company}
										</div>
										<div className="work-subtitle">
											{title}
										</div>
										<div className="work-duration">
											{date}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				}
			/>
			<div className="current-description" onScroll={onScrollHandler} style={{ boxShadow }}>
				<ul>
					{currentJob.description.map((bullet) => (
						<li>{bullet}</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default Works;
