const SEO = [
	{
		page: "home",
		description:
			"I am a frontend engineer with expertise in ReactJS, GraphQL, Node.js, HTML, Sass, TypeScript and more. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Phil", "Phil L", "Phil Li"],
	},
	{
		page: "projects",
		description:
			"Throughout my years of experience, I have been involved in a diverse range of projects, and I am proud of the advancements achieved. I invite you to explore these projects and share your thoughts with me. I am always receptive to fresh ideas and constructive feedback.",
		keywords: ["Phil", "Phil L", "Phil Li"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Phil", "Phil L", "Phil Li"],
	},
];

export default SEO;
