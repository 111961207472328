const INFO = {
	main: {
		title: "Phil's Portfolio",
		name: "Phil Li",
		email: "haojin.li14@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/lihaojin",
		linkedin: "https://www.linkedin.com/in/phil-li-41353b156/",
	},

	homepage: {
		title: "Hi! I'm Phil. I build user experiences for the web.",
		description:
			"As an experienced front-end engineer, I have a strong background in the e-commerce industry. Over several years, I've made successful contributions to various projects, working with both small and large businesses, such as 1stDibs and Anheuser Busch. Throughout my journey, I have developed skills in building responsive user interfaces with an emphasis on SEO and growth.",
	},

	about: {
		title: "I’m Phil Li and I am based out of New York City where I develop things for the web.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "1stDibs",
			description:
				"1stDibs is an online luxury marketplace. I've been working as a front-end engineer here, focusing on SEO and growth. One of the biggest contributions I've had here was the creation of SEO-rich product listing pages based on user search",
			logo: "../dibs-logo.png",
			linkText: "View Project",
			link: "https://www.1stdibs.com/buy/lion-door/",
		},
		{
			title: "Anheuser Busch",
			description: "Anheuser Busch is the world's largest brewing company that owns multiple brands such as Budweiser and Stella Artois. I worked on re-building the user interface for their B2B platform.",
			logo: "../Anheuser-busch-logo.png",
			link: "https://www.bees.com/en",
			linkText: "View Project",
		},
		{
			title: "Erno Laszlo",
			description:
				"Erno Laszlo is a well-known skincare and cosmetics brand. I worked on their online shop, rebuilding their user interface for an improved experience.",
			logo: "../erno-laszlo.jpg",
			linkText: "View Project",
			link: "https://ernolaszlo.com/",
		},
		{
			title: "Muji Canada",
			description:
				"Muji is a Japanese founded international brand that offers stationary and household items. I worked on rebuilding the user interface for their Canada store.",
			logo: "../muji-logo.jpeg",
			linkText: "View Project",
			link: "https://muji.ca/",
		},
		{
			title: "OTC Podcast",
			description:
				"OTC Markets is an exchange for over-the-counter securities not listed in major exchanges. I developed a podcast web application with an audio player that is accessible anywhere in the site.",
			logo: "../otc-markets-logo.jpeg",
			linkText: "View Project",
			link: "https://www.otcmarkets.com/podcast/otcqb",
		},
	],
};

export default INFO;
